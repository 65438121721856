import { Controller } from 'stimulus';

export default class EditInlineController extends Controller {
  toggleEditMode(event, type, showEdit) {
    const objectId = event.target.dataset.id;
    const textElement = document.getElementById(`text_${type}_${objectId}`);
    const editElement = document.getElementById(`edit_${type}_${objectId}`);

    if (showEdit) {
      textElement.classList.add('d-none');
      editElement.classList.remove('d-none');
    } else {
      textElement.classList.remove('d-none');
      editElement.classList.add('d-none');
    }
  }

  editUsername(event) {
    this.toggleEditMode(event, 'username', true);
  }

  cancelEditUsername(event) {
    event.preventDefault();
    this.toggleEditMode(event, 'username', false);
  }

  editAccessCode(event) {
    this.toggleEditMode(event, 'access_code', true);
  }

  cancelEditAccessCode(event) {
    event.preventDefault();
    this.toggleEditMode(event, 'access_code', false);
  }

  editCredential(event) {
    this.toggleEditMode(event, 'credential', true);
  }

  cancelEditCredential(event) {
    event.preventDefault();
    this.toggleEditMode(event, 'credential', false);
  }
}
