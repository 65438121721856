/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import { Controller } from 'stimulus';

export default class PasswordEditController extends Controller {
  static targets = [
    'currentPassword',
    'newPassword',
    'passwordConfirmation',
    'passwordStrengthGuidance',
    'passwordMatchGuidance'
  ]

  toggleNewPasswordField() {
    const currentPasswordLength = this.currentPasswordTarget.value.length;
    this.newPasswordTargets.forEach((element) => {
      if (currentPasswordLength > 0) {
        element.disabled = false;
      } else {
        element.value = '';
        element.disabled = true;
        this.passwordStrengthGuidanceTarget.innerHTML = '';
        this.passwordMatchGuidanceTarget.innerHTML = '';
      }
    });
  }

  togglePasswordStrengthGuidance() {
    const password = this.newPasswordTarget.value;
    const passwordMinLength = parseInt(this.newPasswordTarget.dataset.passwordMinLength, 10);
    const passwordSpecialChars = this.newPasswordTarget.dataset.passwordSpecialChars || "!@#$%^&*";
    const firstName = (this.newPasswordTarget.dataset.firstName || '').toLowerCase();
    const lastName = (this.newPasswordTarget.dataset.lastName || '').toLowerCase();

    let messages = [];
    let strengthClass = 'password-guidance__danger';
    let strengthText = I18n.t(
      'assets.javascripts.controllers.password_edit.password_strength.weak'
    );

    /* eslint-disable no-undef */
    if (password.length >= passwordMinLength) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.length',
            { password_min_length: passwordMinLength }
          )
        }`
      );
    } else {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.length',
            { password_min_length: passwordMinLength }
          )
        }`
      );
    }

    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])/)) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.valid_uppercase_lowercase'
          )
        }`
      );
    } else {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.uppercase_lowercase'
          )
        }`
      );
    }

    if (password.match(/^(?=.*\d)/)) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.valid_includes_number'
          )
        }`
      );
    } else {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.includes_number'
          )
        }`
      );
    }

    if (password.match(new RegExp(`[${passwordSpecialChars}]`))) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.valid_includes_special',
            { password_special_chars: passwordSpecialChars }
          )
        }`
      );
    } else {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.includes_special',
            { password_special_chars: passwordSpecialChars }
          )
        }`
      );
    }

    if (firstName && password.toLowerCase().includes(firstName)) {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.excludes_firstname'
          )
        }`
      );
    } else if (firstName) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.valid_excludes_firstname'
          )
        }`
      );
    }

    if (lastName && password.toLowerCase().includes(lastName)) {
      messages.push(
        `<i class="fa-light fa-circle-xmark password-guidance__danger"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.excludes_lastname'
          )
        }`
      );
    } else if (lastName) {
      messages.push(
        `<i class="fa-light fa-circle-check password-guidance__success"></i> ${
          I18n.t(
            'assets.javascripts.controllers.password_edit.password_rules.valid_excludes_lastname'
          )
        }`
      );
    }

    if (messages.filter(msg => msg.includes('password-guidance__danger')).length === 0) {
      strengthClass = 'password-guidance__success';
      strengthText = I18n.t(
        'assets.javascripts.controllers.password_edit.password_strength.good'
      );
    }
    /* eslint-enable no-undef */

    this.passwordStrengthGuidanceTarget.innerHTML = `
      <p class="password-guidance">
        <small>
          <b>${I18n.t(
            'assets.javascripts.controllers.password_edit.password_strength.message'
          )}
          <span class="${strengthClass}">${strengthText}</span></b>
        </small>
        <br/>
        <small>${messages.join('<br/>')}</small>
      </p>
    `;
  }

  togglePasswordView(event) {
    const passwordToggle = event.currentTarget;
    const passwordField = passwordToggle.previousElementSibling;

    const type = passwordField.type === 'password' ? 'text' : 'password';
    passwordField.setAttribute('type', type);

    if (passwordToggle.classList.contains('fa-eye-slash')) {
      passwordToggle.classList.remove('fa-eye-slash');
      passwordToggle.classList.add('fa-eye');
    } else {
      passwordToggle.classList.remove('fa-eye');
      passwordToggle.classList.add('fa-eye-slash');
    }
  }

  togglePasswordMatchGuidance() {
    if (this.passwordConfirmationTarget.value.length > 0) {
      /* eslint-disable no-undef */
      if (this.passwordConfirmationTarget.value === this.newPasswordTarget.value) {
        this.passwordMatchGuidanceTarget.innerHTML = `
          <p class="password-guidance">
            <small>
              <b>${I18n.t(
                'assets.javascripts.controllers.password_edit.password_match'
              )}
              <i class="fa-light fa-circle-check password-guidance__success"></i></b>
            </small>
          </p>
        `;
      } else {
        this.passwordMatchGuidanceTarget.innerHTML = `
          <p class="password-guidance">
            <small>
              <b>${I18n.t(
                'assets.javascripts.controllers.password_edit.password_match'
              )}
              <i class="fa-light fa-circle-xmark password-guidance__danger"></i></b>
            </small>
          </p>
        `;
      }
      /* eslint-enable no-undef */
    } else {
      this.passwordMatchGuidanceTarget.innerHTML = '';
    }
  }
}
